<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.company.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton
                    color="primary"
                    v-on:click="createTableRow"
                    v-if="checkPermission('create-company')"
                    style="margin-right: 10px"
                >
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Company')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              url="/partner-agencies/list"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Company')"
          >
            <template #companyName="data">
              <span :title="data.row.companyName">{{
                  trimfunction(data.row.companyName, 50)
                }}</span>
            </template>
            <template #email="data">
              <span :title="data.row.email">{{ trimfunction(data.row.email, 50) }}</span>
            </template>
            <template #address="data">
              <span :title="data.row.address">{{ trimfunction(data.row.address, 75) }}</span>
            </template>
            <template #cpName="data">
              <span :title="data.row.cpName">{{ trimfunction(data.row.cpName, 50) }}</span>
            </template>
            <template #actions="data">
              <CButton
                  color="success"
                  v-c-tooltip="$lang.buttons.general.crud.edit"
                  v-on:click="editTableRow(data.row.id)"
                  v-if="checkPermission('edit-company')"
              >
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton
                  color="warning"
                  v-c-tooltip="$lang.company.crud.view"
                  v-on:click="viewTableRow(data.row.id)"
                  v-if="checkPermission('view-company')"
              >
                <i class="fas fa-eye"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-company')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {company, users} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "CompanyItem",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      json_data: [],
      json_label: {},
      messageColor: "success",
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      activePage: 1,
      module: company,
      moduleUser: users,
      filter: {
        type: "",
      },
      columns: ["companyName", "email", "address", "cpName", "actions"], //'status'
      data: [],
      userOptions: [],
      options: {
        headings: {
          companyName: this.$lang.company.table.companyName,
          email: this.$lang.company.table.email,
          address: this.$lang.company.table.address,
          cpName: this.$lang.company.table.cpName,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["companyName", "email", "address", "cpName"],
        sortable: ["companyName", "email", "address", "cpName"],
        filterable: ["companyName", "email", "address", "cpName"],
      },
    };
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.data.map(function (value) {
        self.userOptions.push({value: value.id, label: value.username});
      });
    });
  },
  methods: {
    /* get from child component */
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.data.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                  self.messageColor = "success";
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    createTableRow() {
      this.$router.push({name: "Partner Agency Create"});
    },
    editTableRow(item) {
      this.$router.push({path: `company/edit/${item}`});
    },
    viewTableRow(item) {
      this.$router.push({path: `company/view/${item}`});
    },
    applyFilter() {
      let reqObj = {
        type:
            this.filter.type !== null && Object.keys(this.filter.type).length > 0
                ? this.filter.type.value
                : "",
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      this.filter.type = {};
      store.commit("showLoader", true);
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
  },
};
</script>
